.divider{
    height: 1px;
    background: #FFFFFF;
}

.dividerGridVertical{
    width: 1px;
    background: #FFFFFF;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 3;
    grid-row-end: 4;
    -ms-grid-row-align: auto;
    align-self: auto;
    -ms-grid-column-align: auto;
    justify-self: auto;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
}

.dividerGridHorizontal{
    height: 1px;
    background: #FFFFFF;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 3;
    grid-column-end: 4;
    -ms-grid-row: 2;
    grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -ms-grid-column-align: auto;
    justify-self: auto;
}


@media screen and (max-width: 500px) {
    .dividerGridVertical{
        display: none;
    }
    .dividerGridHorizontal{
        display: none;
    }
}
    