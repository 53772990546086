.page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
}

button.burger {
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
  width: 1290px;
  min-width: 1290px;
  padding: 0px;
  margin: 0px;
  gap: 76px;
}

.navbar {
  display: flex;
  width: 100vw;
  position: fixed;
  height: 80px;
  justify-content: center;
  background: transparent;
  z-index: 100;
  top: 0;
  transition: 300ms;
}

.navbarHide {
  top: -80px;
  transition: 300ms;
}

.navbarContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1290px;
  align-items: center;
  border-bottom: 1px solid #ffffff;
}

.logo {
  width: 82px;
  height: auto;
  fill: #ffffff !important;
}

.navbarLinks {
  display: flex;
  gap: 32px;
}

.navbarLinks a {
  font-size: 16px;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  transition: 300ms;
}

.navbarLinks a:hover {
  margin-top: -4px;
  transition: 300ms;
}

.navbar button {
  padding: 16px 32px;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid #ffffff;
  border-radius: 12px;
  transition: 300ms;
  color: #ffffff;
  cursor: pointer;
}

.navbar button:hover {
  background: #ffffff;
  color: #000000;
  transition: 300ms;
}

.headerLogo{
  cursor: pointer;
}

.phoneButton {
  display: flex;
  gap: 22px;
  align-items: center;
}

.hero {
  display: flex;
  flex-direction: column;
  padding-top: 160px;
  width: 100vw;
  height: 700px;
  align-items: center;
  background: url(./assets/hero_bg.png);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.headingWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.lineWrapper {
  overflow: hidden;
}

.heading {
  font-family: "Stapel";
  font-size: 80px;
  font-weight: 300;
  padding: 0px;
  margin: 0px;
  color: #ffffff;
  text-transform: uppercase;
}

.descriptionBlock {
  width: 100vw;
  padding: 120px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #ffffff;
  border-radius: 60px;
  position: absolute;
  top: 600px;
}

.descriptionWrapper {
  display: flex;
  width: 1290px;
  align-items: center;
  justify-content: center;
}

.description {
  display: flex;
  font-family: "Stapel";
  font-size: 56px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  max-width: 1100px;
  align-items: center;
}

.pageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 200px;
  width: 100vw;
  background: #1c1c1e;
  padding: 500px 0px 0px 0px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 100px;
  width: 1290px;
}

.blockHeadingWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.blockHeading {
  color: #ffffff;
  font-family: "Stapel";
  font-size: 50px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  color: #ffffff;
  text-transform: uppercase;
}

.countsBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.countsImagesWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.countsTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 60px;
  width: 850px;
}

.countsTextWrapper p {
  color: #fff;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
}

.countsGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 50px;
}

.count {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.countText {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.countText h2 {
  font-size: 72px;
  font-weight: 500;
  color: #0bcf6b;
}

.countText p {
  color: #ffffff;
  font-size: 26px;
}

.count1 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 344px;
  height: 344px;
}
.count2 {
  position: absolute;
  left: 0px;
  top: 108px;
  width: 344px;
  height: 344px;
}
.count3 {
  position: absolute;
  left: 0px;
  top: 216px;
  width: 344px;
  height: 344px;
}
.count4 {
  position: absolute;
  left: 0px;
  top: 324px;
  width: 344px;
  height: 344px;
}

.solutionsWrapper{
    width: 100%;
    display: flex;
    gap: 60px;
}

.solutionsList{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 80px;
}

.solution{
    display: flex;
    flex-direction: column;
    gap: 22px;
    cursor: pointer;
    transition: 300ms;
}

.solution:hover{
    gap: 32px;
}

.imageWrapper{
  height: 465px;
  width: 615px;
  overflow: hidden;
}

.solutionImage{
  height: 465px;
  width: 615px;
  transition: 1s;
}

.solution:hover .solutionImage{
    transform: scale(1.1);
}

.textRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.rowRight{
    min-width: 27px;
}

.textRow p{
    color: #ffffff;
    font-size: 32px;
}

.horizontalContent{
  display: flex;
  width: 100%;
  gap: 22px;
}

.otherSolutions{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #2C2C2E;
  border-radius: 8px;
}

.otherSolutionsButton{
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid #FFF;
  width: 560px;
  height: 560px;
  border-radius: 280px;
  transition: 400ms;
  text-align: center;
}

.otherSolutionsButton h1{
  padding: 0px;
  margin: 0px;
  font-weight: 300;
  color: #FFF;
  font-size: 56px;
}

.otherSolutionsButton:hover{
  width: 600px;
  height: 600px;
  border-radius: 300px;
  background: #FFF;
}

.otherSolutionsButton:hover h1{
  color: #000000;
} 

.otherImage{
 width: 100%;
 height: 800px;
 border-radius: 8px;
}

.container{
  display: flex;
  width: 1290px;
  min-width: 1290px;
  padding: 0px;
  margin: 0px;
}