.page_wrapper{
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: #121212;
    align-items: center;
    gap: 40px;
    padding-bottom: 40px;
}

button.burger {
    display: none;
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 1290px;
    min-width: 1290px;
    padding: 0px;
    margin: 0px;
    gap: 76px;
  }
  
  .navbar {
    display: flex;
    width: 100vw;
    position: fixed;
    height: 80px;
    justify-content: center;
    background: transparent;
    z-index: 100;
    top: 0;
    transition: 300ms;
  }
  
  .navbarHide {
    top: -80px;
    transition: 300ms;
  }
  
  .navbarContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1290px;
    align-items: center;
    border-bottom: 1px solid #ffffff;
  }
  
  .logo {
    width: 82px;
    height: auto;
    fill: #ffffff !important;
  }
  
  .navbarLinks {
    display: flex;
    gap: 32px;
  }
  
  .navbarLinks a {
    font-size: 16px;
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
    transition: 300ms;
  }
  
  .navbarLinks a:hover {
    margin-top: -4px;
    transition: 300ms;
  }
  
  .navbar button {
    padding: 16px 32px;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #ffffff;
    border-radius: 12px;
    transition: 300ms;
    color: #ffffff;
    cursor: pointer;
  }
  
  .navbar button:hover {
    background: #ffffff;
    color: #000000;
    transition: 300ms;
  }
  
  .headerLogo{
    cursor: pointer;
  }
  
  .phoneButton {
    display: flex;
    gap: 22px;
    align-items: center;
  }
  
  .headingWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .lineWrapper {
    overflow: hidden;
  }
  
  .heading {
    font-family: "Stapel";
    font-size: 80px;
    font-weight: 300;
    padding: 0px;
    margin: 120px 0px 0px 0px;
    color: #ffffff;
    text-transform: uppercase;
  }

.cases_lsit{
    width: 1290px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 22px;
    row-gap: 40px;
}

.case_image_wrapper{
    display: flex;
    width: 100%;
    aspect-ratio: 638 / 500;
    border-radius: 12px;
    overflow: hidden;
}

.case{
    display: flex;
    flex-direction: column;
    gap: 12px;
    cursor: pointer;
}

.case:hover img{
    transform: scale(1.05);
}

.case p{
    color: #FFFFFF;
    font-size: 22px;
}

.case img{
    width: 100%;
    height: auto;
    transition: 300ms;
    pointer-events: none;
}

.back_button_wrapper{
    display: flex;
    width: 1290px;
    justify-content: start;
}

.back_button{
    display: flex;
    padding: 12px 32px;
    border-radius: 8px;
    color: #FFFFFF;
    border: 1px solid rgba(255,255,255,0.6);
    transition: 300ms;
    opacity: 0.6;
    width: fit-content;
    cursor: pointer;
}

.back_button:hover{
    opacity: 1;
    background: rgba(255, 255, 255, 0.1);
}

.case_container{
    display: flex;
    flex-direction: column;
    width: 1290px;
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 12px;
    overflow: hidden;
}

.case_container img{
    width: 100%;
    height: auto;
    pointer-events: none  ;
}

.solutionsGrid{
  display: grid;
  grid-auto-flow: row dense;
  width: 100%;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  -ms-grid-columns: 1fr 1px 1fr;
  grid-template-columns: 1fr 1px 1fr;
  -ms-grid-rows: auto 1px auto;
  grid-template-rows: auto 1px auto;
  margin-top: 80px;
  margin-bottom: 80px;
}

.solution{
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  gap: 30px;
  padding: 32px;
  align-items: center;
  justify-content: center;
}

.solution h2{
  color: #FFF;
  font-family: Stapel;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
}
.our_clients{
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 1290px;
  width: 100%;
  align-items: center;
}

.solutionImage{
  height: 160px;
  width: auto;
}

.persons_list{
  display: grid;
  margin-top: 32px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1290px;
  width: 100%;
  padding: 0px 40px;
  gap: 32px;
}

.person_info{
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: center;
}

.person_image{
  width: 240px;
  height: auto;
}

.name_info{
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 220px;
}

.person_name{
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 32px;
  padding: 0;
  margin: 0;

}

.person_about{
color: #FFFFFF;
  font-size: 18px;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 700px) {

  .back_button_wrapper{
    display: flex;
    width: 92vw;
    justify-content: start;
  }

  .heading{
    font-family: "Stapel";
    font-size: 46px;
    font-weight: 300;
    padding: 0px;
    margin: 120px 0px 0px 0px;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
  }

  .cases_lsit{
    grid-template-columns: repeat(1, 1fr);
    width: 92vw;
}

  .case_container{
    display: flex;
    flex-direction: column;
    width: 92vw;
    border: 1px solid rgba(255,255,255,0.4);
    border-radius: 12px;
    overflow: hidden;
  }

  .navbar{
    background: #121212;
  }

  .navbarContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 92vw;
    align-items: center;
}

.logo{
    width: 82px;
    height: auto;
    fill: #FFFFFF !important;
}

.navbarLinks{
    display: none;
    gap: 32px;
}

.burgerLinks{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.burgerLinks a{
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    font-weight: 400;
    transition: 300ms;
    width: 100%;
    padding: 28px 22px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);
}

.solutionsGrid{
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 0px;
}

.solution{
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 22px;
  padding: 32px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.solution h2{
  color: #FFF;
  font-family: Stapel;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-transform: uppercase;
}

.solutionImage{
  width: 160px;
  height: auto;
}

.navbar button{
    display: none;
    padding: 16px 32px;
    background: transparent;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #000000;
    border-radius: 12px;
    transition: 300ms;
    cursor: pointer;
}

button.burger{
    display: flex;
    background-color:transparent;
    border:none;
    height:58px;
    outline:none;
    padding:8px;
    position: relative;
    transition: transform 0.7s cubic-bezier(.25,.8,.25,1);
    width:58px;
  }
  
  .burger span{
    background-color:#999999;
    border-radius:25px;
    display:block;
    height:2px;
    position: absolute;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    width:40px;
  }
  
  .burger span.bar_1{
    top:17px;
    width:40px;
  }
  .burger span.bar_2{
    top:29px;
    width:40px;
  }
  .burger span.bar_3{
    top:41px;
    width:40px;
  }
  
  .burger.expanded{
    display: flex;
    height:58px;
    transform:rotate(180deg);
    transition: all 0.7s cubic-bezier(.25,.8,.25,1);
    width:58px;
  }
  
  .burger.expanded span.bar_1{
    left: 18px;
    top: 18px;
    transform-origin: left;
    transform:rotate(45deg);
    width:30px;
    background-color:#172034;
  }
  .burger.expanded span.bar_2{
    opacity:0;
  }
  .burger.expanded span.bar_3{
    left:18px;
    top:40px;
    transform-origin: left;
    transform:rotate(-45deg);
    width:30px;
    background-color:#555555;
  }

  .navbar button:hover{
      background: transparent;
      color: #ffffff;
      transition: 300ms;
  }

  .persons_list{
    display: grid;
    margin-top: 32px;
    grid-template-columns: repeat(1, 1fr);
    max-width: 1290px;
    width: 100%;
    padding: 0px 40px;
    gap: 32px;
  }
  
  .person_info{
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
  }
  
  .person_image{
    width: 220px;
    height: auto;
  }
  
  .name_info{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 220px;
  }
  
  .person_name{
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 32px;
    padding: 0;
    margin: 0;
  
  }
  
  .person_about{
  color: #FFFFFF;
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
}